@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Montserrat";
    src: local("Montserrat-Medium"), url("./Fonts/Montserrat-Medium.ttf") format("truetype");

    font-family: "Roboto";
    src: local("Roboto-Regular"), url("./Fonts/Roboto-Regular.ttf") format("truetype");
}

html {
    font-size: 18px;
}


* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth;
    color: #252D62;
}

a {
    text-decoration: none;
}

a:visited {
    color: #242854;
}

li {
    padding: 3px 0;
}

.CTA {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EE1120;
    padding: .2rem 1.8rem;
    color: #fff !important;
    border-radius: 58px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 32px;
}

.CTA_Disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ee112093;
    padding: .2rem 1.8rem;
    color: #fff !important;
    border-radius: 58px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 32px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type=date]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

section {
    padding-top: 115px;
}

/* ADMIN MODULE STYLES */

:root {
    --background-primary: #1a1c22;
    --background-secondary: #0f0f0f;
    --foreground: #f7f6f8;
    --foreground-secondary: #4b4d53;
    --active: #3c50f8;
    --active-green: #3BF76E;
    --active-red: #EE1120;
}

.background-primary {
    background-color: var(--background-primary);
}

.background-secondary {
    background-color: var(--background-secondary);
}

.background-red {
    background-color: var(--active);
}

.text-active {
    color: var(--active) !important;
}

.text-active-red {
    color: var(--active-red) !important;
}

.text-primary {
    color: var(--foreground) !important;
}

.text-secondary {
    color: var(--foreground-secondary) !important;
}

.separator {
    border-top: 1px solid var(--foreground-secondary);
}

.card {
    width: 10rem;
    height: fit-content;
    border-radius: 10px;
    background-color: var(--background-secondary);
    display: flex;
    flex-direction: column;
    padding: 15px;
    border: 1px solid transparent;
}

.new-card {
    width: fit-content;
    height: fit-content;
    border-radius: 10px;
    background-color: var(--background-secondary);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 15px;
    border: 2px dotted var(--foreground-secondary);
    transition: all 250ms ease-in-out;
}

.new-card:hover {
    border-color: var(--active);
}

/* piso el color text de la web, que es azul. Aca es texto blanco */
.mainAdmin *,
.mainAdmin a {
    color: var(--foreground);
}

.editBtn {
    background-color: var(--active);
    color: var(--foreground);
    border-radius: 10px;
    padding: 8px 15px;
}

.saveBtn {
    background-color: var(--active-green);
    color: var(--background-primary);
    border-radius: 10px;
    padding: 8px 15px;
}

.formInput {
    /* background-color: var(--foreground-secondary); */
    background-color: transparent;
    color: var(--foreground);
    outline: none;
    transition: all ease-in-out 250ms;
}

.formInput[disabled] {
    /* background-color: transparent;
    color: var(--foreground); */
    opacity: .6;
    transition: all ease-in-out 250ms;
}


button {
    outline: none;
}